<template>
  <b-overlay :id="id" :show="show" class="d-inline-block" :opacity="0" variant="transparent">
    <qrcode-vue v-if="data" :value="data" :size="size" level="H" renderAs="svg" :id="id+`Qr`" />
    <template #overlay>
      <div v-if="text" class="text-center text-primary bg-white rounded" style="font-size:48px !important">
        <p :id="id+`Qrs`" class="mx-2 my-1">{{ text }} </p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 250,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    QrcodeVue: () => import('qrcode.vue'),
  },
}
</script>
